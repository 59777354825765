<template>
  <div>
    <el-container>
      <el-header height="auto !important">
        <el-row class="row-left">
          <el-button
            type="info"
            icon="el-icon-refresh-right"
            size="small"
            @click="refresh"
          ></el-button>
          <el-button
            type="primary"
            size="small"
            v-if="relation"
            @click="handleEdit('add')"
            >添加信息</el-button
          >
        </el-row>
        <el-row class="row-right">
          <el-tooltip
            class="item"
            effect="light"
            content="筛选列"
            placement="top-end"
          >
            <el-col><i class="el-icon-s-operation"></i></el-col>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="light"
            content="导出"
            placement="top-end"
          >
            <el-col><i class="el-icon-folder-opened"></i></el-col>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="light"
            content="打印"
            placement="top-end"
          >
            <el-col><i class="el-icon-printer"></i></el-col>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="light"
            content="提示"
            placement="top-end"
          >
            <el-col><i class="el-icon-warning-outline"></i></el-col>
          </el-tooltip>
        </el-row>
      </el-header>
      <el-main v-if="show">
        <el-table :data="tableData" style="width: 100%" @sort-change="sort" :cell-style="columnStyle">
          <el-table-column type="selection" width="45"></el-table-column>
          <el-table-column
            fixed
            prop="id"
            label="ID"
            width="60"
            sortable='custom'
          ></el-table-column>
          <el-table-column
            prop="riceuser"
            label="小米运动账号"
            width="120"
            align="center"
          >
          </el-table-column>
          <template v-if="relation">
            <el-table-column
              prop="createtime"
              label="创建时间"
              width="240"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="runtime"
              label="每天执行时间"
              min-width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="step"
              label="每日走路步数"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="runtype"
              label="步数单位"
              width="100"
              align="center"
            >
            </el-table-column>
          </template>
          <template v-if="log">
            <el-table-column
              prop="runstep"
              label="执行步数"
              min-width="140"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="runtime"
              label="执行时间"
              min-width="240"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="runstatus"
              label="执行状态"
              min-width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="runmsg"
              label="执行信息"
              min-width="150"
              align="center"
            >
            </el-table-column>
          </template>
          <el-table-column
            prop="code"
            label="识别码"
            width="120"
            align="center"
          >
          </el-table-column>
          <template v-if="relation">
            <el-table-column
              prop="status"
              label="账号状态"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <!-- 小米帐号状态 -->
                <el-switch
                  v-model="scope.row.status"
                  @change="changeStatus(scope.row, $event)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column
              prop="handle"
              label="操作"
              width="240"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="success"
                  @click="handleSubmit(scope.row)"
                  >执行</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleEdit('edit', scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column></template
          >
        </el-table>
        <el-dialog
          :title="edittype == 'add' ? '增加关联信息' : '编辑信息'"
          :visible.sync="dialogFormVisible"
          :close-on-click-modal="false"
          :before-close="handleClose"
        >
          <el-form :model="form" :rules="rules" ref="form" status-icon>
            <el-form-item
              label="账号："
              :prop="edittype == 'add' ? 'riceuser' : ''"
            >
              <el-input
                v-model="form.riceuser"
                :disabled="edittype == 'add' ? false : true"
                maxlength="11"
                prefix-icon="el-icon-user-solid"
                :placeholder="
                  edittype == 'add' ? '小米运动账号' : item.riceuser
                "
              ></el-input>
            </el-form-item>
            <el-form-item
              label="密码："
              :prop="edittype == 'add' ? 'ricepass' : ''"
            >
              <el-input
                v-model="form.ricepass"
                type="password"
                maxlength="16"
                prefix-icon="el-icon-lock"
                :placeholder="`小米运动密码${
                  edittype == 'add' ? '' : '，不填则不修改'
                }`"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="智能托管：输入3，选择万，就是3万多步；输入32，选择千，就是3万2千多步"
              prop="mindstep"
            >
              <el-input
                v-model="form.mindstep"
                :maxlength="this.mindsteplen+1"
                placeholder="每天想走多少步"
              ></el-input>
            </el-form-item>
            <el-form-item label="选择每日更改单位：">
              <el-radio-group v-model="form.runtype">
                <el-radio label="百"></el-radio>
                <el-radio label="千"></el-radio>
                <el-radio label="万"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="执行时间：仅精确到分钟" prop="runtime">
              <el-time-picker
                placeholder="选择时间"
                :value="edittype == 'add' ? '' : item.runtime"
                v-model="form.runtime"
                value-format="HH:mm:ss"
                style="width: 100%"
                :picker-options="{
                  selectableRange: '05:00:00 - 23:00:00',
                }"
              ></el-time-picker>
              <small>本平台23点到5点之间不受理代步业务</small>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="danger"
              @click="dialogFormVisible = false"
              style="width: calc(50% - 5px)"
              >关闭窗口</el-button
            >
            <el-button
              type="primary"
              @click="edittype == 'add' ? handleAdd() : handleUpdate()"
              style="width: calc(50% - 5px)"
              >{{ edittype == "add" ? "保存数据" : "更改信息" }}</el-button
            >
          </div>
        </el-dialog>
      </el-main>
      <el-footer height="auto" v-if="show">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
        >
        </el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { relation } from "../api";
export default {
  name: "Relation",
  data() {
    var validateRickuser = (rule, value, callback) => {
      let reg = new RegExp(
        "^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\\d{8}$"
      );
      if (!reg.test(value)) {
        callback(new Error("小米运动账号格式不正确"));
      } else {
        callback();
      }
    };
    var validateRickpass = (rule, value, callback) => {
      if (!/^[a-zA-Z0-9]{6,16}$/.test(value)) {
        callback(new Error("小米运动密码格式不正确"));
      } else {
        callback();
      }
    };
    var validateMindstep = (rule, value, callback) => {
      let reg = new RegExp("^[1-9][0-9]{0," + this.mindsteplen + "}$");
      if (!reg.test(value)) {
        callback(new Error("智能托管格式不正确"));
      } else {
        callback();
      }
    };
    return {
      currentPage: 1, // 当前页
      pageSize: 10, // 每页显示数量
      show: true, // 用于表格刷新
      dialogFormVisible: false, // 弹窗表单显示
      form: {
        // 弹窗表单数据
        riceuser: "",
        ricepass: "",
        mindstep: "",
        runtype: "",
        runtime: "",
      },
      rules: {
        riceuser: [
          { required: true, message: "请输入小米运动账号" },
          { validator: validateRickuser, trigger: "blur" },
        ],
        ricepass: [
          { required: true, message: "请输入小米运动密码" },
          { validator: validateRickpass, trigger: "blur" },
        ],
        mindstep: [
          { required: true, message: "智能托管不能为空" },
          { validator: validateMindstep, trigger: "blur" },
        ],
        runtime: [{ required: true, message: "执行时间不能为空" }],
      },
      edittype: "", // 编辑类型
      item: {}, // 当前编辑项
    };
  },
  props: ["relation", "log", "tableDatas", "getTableDatas"],
  computed: {
    // 列表数据
    tableData() {
      // 对完整数据进行分页
      let temp = this.tableDatas.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
      // 确定开关状态
      for (let i = 0; i < temp.length; i++) {
        if(!temp[i].status)break;
        temp[i].status = temp[i].status == 1 ? true : false;
      }
      // 确定账号执行状态
      for (let i = 0; i < temp.length; i++) {
        if(!temp[i].runstatus)break;
        temp[i].runstatus = temp[i].runstatus == 1 || temp[i].runstatus == '正常' ? '正常' : '异常';
      }
      return temp;
    },
    // 总数
    total() {
      return this.tableDatas.length;
    },
    // 智能托管限制长度
    mindsteplen() {
      return this.form.runtype == "百" ? 2 : this.form.runtype == "千" ? 1 : 0;
    },
  },
  mounted() {},
  methods: {
    // 对日志运行状态及执行信息添加颜色样式(正常：绿色；异常：红色)
    columnStyle({columnIndex, row}) {
      if((columnIndex == 5 || columnIndex == 6) && this.log) {
        return {color: row.runstatus == '正常' ? '#67C23A' : '#F56C6C'}
      }
    },
    // 切换显示状态(实现刷新)
    refresh() {
      // 销毁列表实例，等待页面DOM渲染后重新创建
      this.show = false;
      this.getTableDatas();
      this.$nextTick(() => {
        setTimeout(() => {
          this.show = true;
        }, 100);
      });
    },
    // 通过父组件获取排序后的列表数据
    sort({order}){
      this.getTableDatas(order);
    },
    // 改变账号状态
    changeStatus(row, status) {
      relation.change({
        code: row.code,
        status: status
      }).then((res) => {
        this.refresh();
        this.$message({
          message: "账号 " + row.riceuser + " 的任务已" + res.msg,
          type: "success",
        });
      });
    },
    // 改变每页显示条目个数
    handleSizeChange(val) {
      this.pageSize = val;
    },
    // 改变当前页数
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    // 执行
    handleSubmit(row) {
      this.$confirm(`您是否现在执行${row.riceuser}这个账号的任务`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        iconClass: "el-icon-question",
        closeOnClickModal: false, //点击遮罩层不关闭
      })
        .then(() => {
          // 点击确定执行后弹出输入框
          this.$prompt("输入您想更改的步数", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            inputPattern: /^[1-9][0-9]{0,4}$/,
            inputErrorMessage: "步数不合法",
            closeOnClickModal: false, //点击遮罩层不关闭
          })
            .then(({ value }) => {
              // 点击确定后提交执行
              relation.submit({
                code: row.code,
                step: value,
              }).then((res) => {
                this.refresh();
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              });
            })
            .catch(() => {
              this.$message({
                message: "取消更改",
                type: "info",
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消执行!",
          });
        });
    },
    // 打开编辑弹窗
    handleEdit(type, row) {
      this.edittype = type;
      if (row) {
        this.item = row;
        this.form.runtype = row.runtype;
        this.form.runtime = row.runtime;
        this.form.mindstep = row.mindstep;
      } else {
        this.item = {};
        this.form.runtype = "百";
      }
      this.dialogFormVisible = true;
    },
    // 关闭编辑弹窗
    handleClose(done) {
      // 清空form表单的值
      this.form = {
        riceuser: "",
        ricepass: "",
        mindstep: "",
        runtype: "",
        runtime: "",
      };
      done();
    },
    // 删除
    handleDelete(row) {
      this.$confirm("确定要删除该条数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          relation.del({
            code: row.code,
          }).then((res) => {
            this.refresh();
            this.$message({
              message: res.msg,
              type: "success",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除!",
          });
        });
    },
    // 新增关联
    handleAdd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
            relation
              .add({
                riceuser: this.form.riceuser,
                ricepass: this.form.ricepass,
                mindstep: this.form.mindstep,
                runtype: this.form.runtype,
                runtime: this.form.runtime,
              })
              .then((res) => {
                this.refresh();// 完成添加后刷新列表
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.dialogFormVisible = false;
              });
        } else {
          this.$message({
            type: "error",
            message: "请检查输入!",
          });
        }
      });
    },
    // 确认修改
    handleUpdate() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.form.ricepass || /^[a-zA-Z0-9]{8,16}$/.test(this.form.ricepass)) {// 密码为空或验证通过 则执行修改
            relation.update({
              ricepass: this.form.ricepass,
              mindstep: this.form.mindstep,
              runtype: this.form.runtype,
              runtime: this.form.runtime,
              code: this.item.code
            }).then((res) => {
              this.refresh();//完成修改后刷新列表
              this.$message({
                type: "success",
                message: res.msg,
              });
            });
            this.dialogFormVisible = false;
          } else {
            this.$message({
              type: "error",
              message: "密码不合法!",
            });
          }
        } else {
          this.$message({
            type: "error",
            message: "请检查输入!",
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.el-container {
  padding: 10px;
  background-color: #fff;
  .el-header,
  .el-main,
  .el-footer {
    padding: 0;
    border: 1px solid #ebeef5;
  }
  .el-header {
    position: relative;
    box-shadow: none;
    .row-left {
      padding: 0 10px;
      background-color: #fff;
    }
    .row-right {
      position: absolute;
      right: 30px;
      top: 16px;
      .el-col {
        width: 26px;
        height: 26px;
        padding: 5px;
        line-height: 16px;
        margin-right: 10px;
        text-align: center;
        color: #333;
        border: 1px solid #ccc;
        cursor: pointer;
      }
    }
  }
  .el-footer {
    padding: 7px;
    .el-pagination {
      background-color: #fff;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        height: 3px;
        width: 3px;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #1e9fff;
      }
    }
  }
}
.el-form-item {
  margin-bottom: 15px;
}
</style>